import { EMPTY_MASTER_TARIFF_TYPEANY_MODEL } from "presentation/model/TariffType/TariffTypeMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: TariffTypeMaintenanceProvider,
    useTracked: useTariffTypeMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_TARIFF_TYPEANY_MODEL), {concurrentMode: true});
export { TariffTypeMaintenanceProvider, useTariffTypeMaintenanceTracked };

