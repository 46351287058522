
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EMPTY_TARIFF_TYPE_ENTITY, TariffTypeEntity } from "domain/entity/TariffType/TariffTypeEntity";
import { TariffTypeRepository } from "domain/repository/TariffType/TariffTypeRepo";
import _ from "lodash";
import { createTariffTypeMaintenanceValidationSchema } from "presentation/constant/TariffType/TariffTypeMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { TariffTypeMaintenanceModel } from "presentation/model/TariffType/TariffTypeMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TariffTypeMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TariffTypeMaintenanceModel>> | ((value: SetStateAction<TariffTypeMaintenanceModel>) => void),
    ],
    repo: TariffTypeRepository,
}

export const TariffTypeMaintenanceVM = ({dispatch,repo}:TariffTypeMaintenanceVMProps) => {
    const [tariffTypeMainDispatch] = dispatch;
        
    const loadDropdownOption = async () => {
    }

    const onSearch = async () => {
        const entities = await repo.getEntities();

        tariffTypeMainDispatch(prevState =>({
            ...prevState,
            tableData: entities,
            selectedRows: [],
            currentSelectedRow: EMPTY_TARIFF_TYPE_ENTITY,
            isBackMaster: false,
        }))
    }

    const updateSelectedRows = async (allRows:TariffTypeEntity[], selecedRows:TariffTypeEntity[]) => {
        tariffTypeMainDispatch(prevState => {
             
            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: selecedRows,
            }
        })
    }

    const onAdd = () => {
        tariffTypeMainDispatch(prevState => {
             
            return {
                ...prevState,
                isShowEditPanel: true,
                masterState:{
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_TARIFF_TYPE_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: TariffTypeEntity)=>{
        tariffTypeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState:{
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onEditClick = (currentEntity: TariffTypeEntity)=>{
        tariffTypeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState:{
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = ()=>{
        tariffTypeMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_TARIFF_TYPE_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        tariffTypeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_TARIFF_TYPE_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_TARIFF_TYPE_ENTITY,
                    allFormState:{}
                },
                isBackMaster: true,
                isShowEditPanel: false,
            }
        })
    }

    const onSaveClicked = () => {
        tariffTypeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: TariffTypeEntity,isAdd: boolean) => {
        const valResult = isAdd? await Validation(createTariffTypeMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null;
        let validatedResult: {[x: string]: string} = {};  
        if (valResult) {
            validatedResult = {...valResult, warningMessage: 'Please input the missing value.' }; 

            tariffTypeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }                        
                }
            });

            const res: ResponseEntity={
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }

        if(isAdd){
            return await repo.createEntity(currentEntity);
        }else{
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        tariffTypeMainDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingEntity : {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked?"Y":"N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if(fieldKey === 'tariffType'){
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        tariffTypeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        tariffTypeMainDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                },
            }
        }))
    }


    return {
        onDateRangeChange: onDateRangeChange,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onEditClick: onEditClick,
    }
}