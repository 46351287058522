import { TariffTypeMaintenanceConstant } from "presentation/constant/TariffType/TariffTypeMaintenanceConstant";
import { useTariffTypeMaintenanceVM } from "presentation/hook/TariffType/useTariffTypeMaintenanceVM";
import { useTariffTypeMaintenanceTracked } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import TariffTypeEditPanelTitleBar from "./TariffTypeEditPanelTitleBar";

export const TariffTypeMaintenanceFormPanel = () => {
    const [tariffTypeState] = useTariffTypeMaintenanceTracked();
    const tariffTypeVM = useTariffTypeMaintenanceVM();
    const {currentSelectedRow, masterState} = tariffTypeState;
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState;
    const SCREEN_CONSTANT = TariffTypeMaintenanceConstant;
    

    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffType || ''}
                fieldValue={currentEntity?.tariffType}
                fieldLabel={SCREEN_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={3}
                options={tariffTypeState.dynamicOptions.tariffTypeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffTypeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
        , [allFormState, isAdd, currentEntity?.tariffType, SCREEN_CONSTANT.TARIFF_TYPE, isSaveClicked, tariffTypeState.dynamicOptions.tariffTypeTypeDropdownOptions, tariffTypeVM])

        const memoDesc = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={!(isEditable || isAdd)}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffTypeDesc || ''}
                    fieldValue={currentEntity?.tariffTypeDesc}
                    fieldLabel={SCREEN_CONSTANT.TARIFF_TYPE_DESC}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffTypeDesc'}
                    isShowMissingError={true}
                    maxLength={35}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffTypeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isEditable, isAdd, currentEntity?.tariffTypeDesc, SCREEN_CONSTANT.TARIFF_TYPE_DESC, isSaveClicked, tariffTypeVM])

        const memoActiveInd = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd==='Y'}
                    disabled={!(isEditable || isAdd)}
                    onChange={(e) => tariffTypeVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isAdd, isEditable, tariffTypeVM])

        
    const memoEditPanelTitleBar = useMemo(() => {
        return <TariffTypeEditPanelTitleBar/>
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>{!isAdd ? currentEntity.tariffType : "NEW CODE"}</SidebarTitle>

                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        
                        <CriteriaItemContainer>
                            {memoTariffType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoDesc}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
