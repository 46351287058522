import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TariffTypeMaintenanceProvider } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { TariffTypeMasterView } from "presentation/view/section/TariffType/TariffTypeMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TariffTypeMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.TARIFF_TYPE_MAINTENANCE}>
        <MessageBarWrapper>
            <TariffTypeMaintenanceProvider>
                <GridStyles/>
                <TariffTypeMasterView/>
            </TariffTypeMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TariffTypeMaintenanceCont;