
export interface TariffTypeEntity {
    id: number,
    tariffType: string,
    tariffTypeDesc: string | null,
    activeInd: string | null,

    [key: string]: string | number | null | undefined
}

export const EMPTY_TARIFF_TYPE_ENTITY: TariffTypeEntity = {
    id: 0,
    tariffType: "",
    tariffTypeDesc: null,
    activeInd: "Y"
}

