import * as yup from 'yup';


export const commonTariffTypeMaintenanceValidation: { [x: string]: any; } = {
  tariffType: yup.string().required("Missing"),
};

export const createTariffTypeMaintenanceValidation: { [x: string]: any; } = {
  ...commonTariffTypeMaintenanceValidation,
};

export const createTariffTypeMaintenanceValidationSchema = yup.object().shape(createTariffTypeMaintenanceValidation);

