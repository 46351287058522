
import { useTariffTypeMaintenanceVM } from "presentation/hook/TariffType/useTariffTypeMaintenanceVM";
import { useTariffTypeMaintenanceTracked } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffTypeTablePanel from "./TariffTypeTablePanel";

const TariffTypeMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tariffTypeState] = useTariffTypeMaintenanceTracked();
    const tariffTypeVM = useTariffTypeMaintenanceVM();
    const { isBackMaster } = tariffTypeState;


    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    tariffTypeVM.onSearch(),
                    tariffTypeVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(() => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [isBackMaster, tariffTypeVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<TariffTypeTablePanel />}
            />
        </div>
    </>
}

export default memo(TariffTypeMaintenance);