
import { useTariffTypeMaintenanceTracked } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffTypeMaintenance from "./TariffTypeMaintenance";
import { TariffTypeMaintenanceFormPanel } from "./TariffTypeMaintenanceFormPanel";

export const TariffTypeMasterView: React.FC = () => {
    const [tariffTypeState] = useTariffTypeMaintenanceTracked();
    const { isShowEditPanel } = tariffTypeState;

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <TariffTypeMaintenance />
            {
                (isShowEditPanel &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<TariffTypeMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
}