import { TariffTypeRepoImpl } from "domain/repository/TariffType/TariffTypeRepoImpl";
import { useTariffTypeMaintenanceTracked } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import { TariffTypeMaintenanceVM } from "presentation/viewModel/TariffType/TariffTypeMaintenanceVM";
import { useMemo } from "react";

export const useTariffTypeMaintenanceVM = () => {
    const [, setTariffTypeMaintenanceState] = useTariffTypeMaintenanceTracked();
    const tariffTypeMainVM = useMemo(() =>
        TariffTypeMaintenanceVM({
            dispatch: [setTariffTypeMaintenanceState],
            repo: TariffTypeRepoImpl(),
        }), [setTariffTypeMaintenanceState])

    return tariffTypeMainVM
}

